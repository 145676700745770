.icon.myTechnician__preparation-icon {
  margin: 0 0.5em;
}

.myTechnician__preparation-info {
  text-align: left;
}

.myTechnician__background {
  margin-top: -30px;
  background-image: url("https://res.cloudinary.com/telia/image/upload/q_auto:best,a_hflip,e_saturation:-20,c_crop,x_100,y_240,w_3000,h_1300/c_scale,h_450/e_gradient_fade:symmetric,x_0.2,b_rgb:FFF/fl_ignore_aspect_ratio,w_2500,c_pad/v1530090318/other/minutehnik-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
}

.myTechnician__title-margin {
  margin-left: 3em;
}

.myTechnician__btn-margin {
  margin-top: 1em;
  margin-bottom: 1em;
}

.myTechnician__btn-margin.btn--small {
  margin: 0.3em;
}

.myTechnician__map-label {
  display: inline-block;
  position: relative;
  bottom: 55px;
  padding:6px 12px;
  background:#fff;
  border-radius:100rem;
  font-size: 15px;
  font-weight: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.myTechnician__map-disabled {
  background-color: #000;
  position: relative;
}

.myTechnician__map-disabled .myTechnician__map-container {
  opacity: 0.5;
}

.myTechnician__map-disabled > div.text {
  left:50%;
  top:50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position:absolute;
}

@media only screen and (max-width: 600px) {
  .myTechnician__title-margin {
    margin-left: 0;
  }
  .myTechnician__background {
    background-image: url("https://res.cloudinary.com/telia/image/upload/q_auto:best,a_hflip,e_saturation:-20,c_crop,x_800,y_220,w_1600,h_1600/w_700/v1530090318/other/minutehnik-bg.png");
    background-size: 100%;
  }
  .myTechnician__grid>.grid__col {
    text-align: center;
  }

}
